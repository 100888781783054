<template>
  <div class="list list--profiles">
    <div
      v-for="item in list"
      :key="item"
    >
      <UserInfo :profile="item" />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue"
import { loadProfiles } from "../services.js"
import UserInfo from "./UserInfo.vue";

export default defineComponent({
  name: "Profiles",
  components: {
    UserInfo,
  },
  setup() {
    const items = ref([])

    onMounted(async () => {
      items.value = await loadProfiles()
    })

    return {
      items
    }
  },
  computed: {
    list() {
      const search = this.$route.query.country

      if (search) {
        return this.items.filter(item => item?.country.toLowerCase().includes(search))
      }

      return this.items
    }
  },
})
</script>

<style lang="scss" scoped>
.list--profiles {
  padding: $margin;
}
</style>
